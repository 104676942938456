import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../../api_helper';

export const productionAnalysisApi = createApi({
  reducerPath: 'productionAnalysis',
  baseQuery: axiosBaseQuery,
  tagTypes: ['overview', 'pvChart'],
  endpoints: (builder) => ({
    getOverview: builder.query({
      query: ({
        from,
        to,
        type
      }) => {
        const params = type === 'overall' ? { view: type } : { from, to, view: type };

        return ({
          url: '/production-analysis/overview',
          method: 'get',
          params
        });
      },
      providesTags: ['overview']
    }),
    getPvChart: builder.query({
      query: ({
        from,
        to,
        type,
        gatewayId,
        stringsId
      }) => {
        const params = type === 'overall' ? { view: type, stringsId } : { from, to, view: type, stringsId };

        return ({
          url: `/production-analysis/pv-chart/${gatewayId}`,
          method: 'get',
          params
        });
      },
      providesTags: ['pvChart']
    }),
    getHeatmap: builder.query({
      query: ({
        from,
        to,
        type,
        gatewayId,
        stringsId
      }) => ({
        url: `/production-analysis/heatmap/${gatewayId}`,
        method: 'get',
        params: { from, to, view: type, stringsId }
      }),
      providesTags: ['heatmap']
    }),
    getZips: builder.query({
      query: () => ({
        url: '/users/zips',
        method: 'get'
      }),
      transformResponse: (data) => data.map((zip) => ({ label: zip, value: zip }))
    }),
    getLabels: builder.query({
      query: () => ({
        url: '/gateway/labels',
        method: 'get'
      }),
      transformResponse: (data) => data.map((label) => ({ label, value: label })),
      providesTags: ['labels']
    }),
    createLabel: builder.mutation({
      query: (data) => ({
        url: '/gateway/labels',
        method: 'post',
        data
      }),
      invalidatesTags: ['labels']
    }),
    removeLabel: builder.mutation({
      query: ({ gatewayId }) => ({
        url: `/gateway/labels/${gatewayId}`,
        method: 'delete'
      }),
      invalidatesTags: ['labels']
    })

  })
});

export const {
  useGetOverviewQuery,
  useGetPvChartQuery,
  useGetHeatmapQuery,
  useGetZipsQuery,
  useGetLabelsQuery,
  useCreateLabelMutation,
  useRemoveLabelMutation
} = productionAnalysisApi;
