import React from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { defaultValue } from './constants';
import CarChargingTooltip from './components/CarChargingTooltip';
import SwitchesTooltip from './components/SwitchesTooltip';
import SmartPlugTooltip from './components/SmartPlugTooltip';
import BatteryTooltip from './components/BatteryTooltip';
import HeatpumpTooltip from './components/HeatpumpTooltip';
import WaterHeaterTooltip from './components/WaterHeaterTooltip';
import EnergyMeasurementTooltip from './components/EnergyMeasurementTooltip';
import CarChargingSystemTooltip from './components/CarChargingSystemTooltip';
import InverterTooltip from './components/InverterTooltip';
import SmartMeterTooltip from './components/SmartMeterTooltip';
import InputDeviceTooltip from './components/InputDeviceTooltip';
import V2XTooltip from './components/V2XTooltip';
import TemperatureMeasurementTooltip from './components/TemperatureMeasurementTooltip';
import HomeAppliancesTooltip from './components/HomeAppliancesTooltip';
import { getUserDevicesLastSensorsDataSelector } from '../../../../redux-store/selectors/userDevices';

import './index.scss';
import CarTooltip from './components/CarTooltip';

const devicesType = [
  'Car Charging',
  'Switches',
  'Smart Plug',
  'Heatpump',
  'Battery',
  'Water Heater',
  'Energy Measurement',
  'Car Charging System',
  'Inverter',
  'Smart Meter',
  'Input Device',
  'V2X',
  'Temperature',
  'Appliances',
  'Car'
];

/**
 * Render function for React-Table. Column for sensors data
 * @memberof module:ReactTable
 * @param  {object} original - props of the gateway
 */
const RenderSensorData = (props) => {
  const { original: { _id, type } } = props;
  const sensorsData = useSelector(getUserDevicesLastSensorsDataSelector);

  const sensorData = sensorsData?.find((el) => el._id === _id);
  const {
    switchState,
    inputState,
    heatingAdjustment,
    onTimeToday,
    tempInside,
    tempOutside,
    warmWaterTemp,
    currentOperationState,
    energyToday,
    consumedForLast24h,
    currentWaterTemp,
    currentPower,
    currentPowerInvSm,
    SOC,
    currentPercentOn,
    currentPowerBattery,
    dischargedForLast24Hours,
    chargedForLast24Hours,
    homeApplianceStatus,
    remainingTime,
    powerSettingPercent,
    heaterstep,
    waterHeaterMode,
    heatModeAuto,
    heatPumpMode,
    CurrentEnergyDeliveryTariff1,
    CurrentEnergyDeliveryTariff2,
    CurrentEnergyPurchaseTariff1,
    CurrentEnergyPurchaseTariff2,
    activeDevice
  } = sensorData || {};

  const energyDelivery = (CurrentEnergyDeliveryTariff2 && CurrentEnergyDeliveryTariff1) && CurrentEnergyDeliveryTariff2 - CurrentEnergyDeliveryTariff1;
  const energyPurchase = (CurrentEnergyPurchaseTariff2 && CurrentEnergyPurchaseTariff1) && CurrentEnergyPurchaseTariff2 - CurrentEnergyPurchaseTariff1;

  // convert W to kW
  const convertWtoKW = (value) => (value ? Number(value) / 1000 : defaultValue);
  const convertedCurrentPower = convertWtoKW(currentPower);
  const convertedCurrentPowerBattery = convertWtoKW(currentPowerBattery);

  const renderTooltipContent = {
    'Car Charging': (
      <CarChargingTooltip
        switchState={switchState}
        consumedForLast24h={consumedForLast24h}
        convertedCurrentPower={convertedCurrentPower}
      />
    ),
    Switches: (
      <SwitchesTooltip
        switchState={switchState}
        consumedForLast24h={consumedForLast24h || defaultValue}
        convertedCurrentPower={convertedCurrentPower}
      />
    ),
    'Smart Plug': (
      <SmartPlugTooltip
        switchState={switchState}
        convertedCurrentPower={convertedCurrentPower}
        consumedForLast24h={consumedForLast24h}
      />
    ),
    Battery: (
      <BatteryTooltip
        convertedCurrentPower={convertedCurrentPower}
        SOC={SOC}
        activeDevice={activeDevice}
        chargedForLast24Hours={chargedForLast24Hours}
        dischargedForLast24Hours={dischargedForLast24Hours}
      />
    ),
    Heatpump: (
      <HeatpumpTooltip
        heatingAdjustment={heatingAdjustment}
        onTimeToday={onTimeToday}
        tempInside={tempInside}
        tempOutside={tempOutside}
        warmWaterTemp={warmWaterTemp}
        currentOperationState={currentOperationState}
        energyToday={energyToday}
        convertedCurrentPower={convertedCurrentPower}
        heatPumpMode={heatPumpMode}
      />
    ),
    'Water Heater': (
      <WaterHeaterTooltip
        currentWaterTemp={currentWaterTemp}
        convertedCurrentPower={convertedCurrentPower}
        currentPercentOn={currentPercentOn}
        consumedForLast24h={consumedForLast24h}
        heatModeAuto={heatModeAuto}
        heaterstep={heaterstep} // !deprecated
        waterHeaterMode={waterHeaterMode}
        powerSettingPercent={powerSettingPercent}
      />
    ),
    'Energy Measurement': (
      <EnergyMeasurementTooltip
        convertedCurrentPower={convertedCurrentPower}
      />
    ),
    'Car Charging System': (
      <CarChargingSystemTooltip
        consumedForLast24h={consumedForLast24h}
        convertedCurrentPower={convertedCurrentPower}
      />
    ),
    Inverter: (
      <InverterTooltip currentPowerInvSm={currentPowerInvSm} />
    ),
    'Smart Meter': (
      <SmartMeterTooltip
        currentPowerInvSm={currentPowerInvSm}
        energyDelivery={energyDelivery}
        energyPurchase={energyPurchase}
      />
    ),
    'Input Device': (
      <InputDeviceTooltip inputState={inputState} />
    ),
    V2X: (
      <V2XTooltip
        convertedCurrentPowerBattery={convertedCurrentPowerBattery}
        SOC={SOC}
        chargedForLast24Hours={chargedForLast24Hours}
        dischargedForLast24Hours={dischargedForLast24Hours}
      />
    ),
    Temperature: (
      <TemperatureMeasurementTooltip currentWaterTemp={currentWaterTemp} />
    ),
    Appliances: (
      <HomeAppliancesTooltip remainingTime={remainingTime} homeApplianceStatus={homeApplianceStatus} />
    ),
    Car: (
      <CarTooltip
        chargedForLast24Hours={chargedForLast24Hours}
        SOC={SOC}
      />
    )
  };

  return devicesType.includes(type) ? (
    <Tooltip
      title={<div className="sensorData-container">{renderTooltipContent[type]}</div>}
      classes={{ tooltip: 'sensorData-tooltip', popper: 'sensorData-popper' }}
      enterTouchDelay={0}
      leaveTouchDelay={10000}
    >
      <i className="la la-info-circle" />
    </Tooltip>
  ) : null;
};

RenderSensorData.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default RenderSensorData;
