import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

import Tooltip from '../../Tooltip';
import i18n from '../../../i18n';

/**
 * Render function for React-Table. SubComponent for user detail info.
 * @memberof module:ReactTable
 * @param  {Object} props - React component properties
 * @param  {Object} props.original - props of the user details
 * @param  {string} props.original.first_name - users first name
 * @param  {string} props.original.last_name - users last name
 * @param  {string} props.original.email - users email
 * @param  {string} props.original.street - users street
 * @param  {string} props.original.city - users city
 * @param  {string} props.original.zip - users ZIP
 * @param  {string} props.original._id - users id
 * @param  {string} props.original.gateway - users pv-installer
 * @param  {string} props.original.userName - users name
 */
const UserInfoSub = ({ original: { first_name, last_name, email, street, city, zip, _id, gateway, userName } }) => {
  const rowStyle = { display: 'flex',
    gap: '20px',
    '.table-row-details-head': {
      minWidth: '130px'
    } };

  return (
    <div className="table-details">
      <div className="container-fluid">
        <Box sx={{ display: 'flex', gap: '100px' }}>
          <Box sx={{ width: 'fit-content' }} className="justify-content-start">
            <Box sx={rowStyle}>
              <div className="table-row-details-head">
                {i18n.t('firstName')}
              </div>
              <div className="table-row-details-td">{first_name}</div>
            </Box>
            <Box sx={rowStyle}>
              <div className="table-row-details-head">
                {i18n.t('lastName')}
              </div>
              <div className="table-row-details-td">{last_name}</div>
            </Box>
            <Box sx={rowStyle}>
              <div className="table-row-details-head">
                {i18n.t(userName ? 'username' : 'mail')}
              </div>
              <div className={`table-row-details-td ${userName ? '' : 'email-detail-table'}`}>
                {userName || <a href={`mailto:${email}`}>{email}</a>}
              </div>
            </Box>
          </Box>
          <Box className="justify-content-start">
            <Box sx={rowStyle}>
              <div className="table-row-details-head">
                {i18n.t('street')}
              </div>
              <div className="table-row-details-td">{street}</div>
            </Box>
            <Box sx={rowStyle}>
              <div className="table-row-details-head">
                {i18n.t('city')}
              </div>
              <div className="table-row-details-td">{city}</div>
            </Box>
            <Box sx={rowStyle}>
              <div className="table-row-details-head">
                {i18n.t('zip')}
              </div>
              <div className="table-row-details-td">{zip}</div>
            </Box>
          </Box>
          {gateway?.productionAnalysis && gateway?.label && (
          <Box className="justify-content-start">
            <Box sx={rowStyle}>
              <div className="table-row-details-head">
                {i18n.t('label')}
              </div>
              <div className="table-row-details-td">{gateway.label}</div>
            </Box>
          </Box>
          )}
          {gateway?.errors?.length > 0 && (
          <Box className="justify-content-start">
            <Box sx={rowStyle}>
              <div className="table-row-details-head">
                {i18n.t('error')}
                :
              </div>
              <div className="col-6 table-row-details-td">
                {gateway.errors.map(({ code }) => (
                  <div key={uuidv4()} className="error_status_yes">
                    {code}
                    {i18n.exists(`error_${code}`) && <Tooltip title={i18n.t(`error_${code}`)} />}
                  </div>
                ))}
              </div>
            </Box>
          </Box>
          )}
          {gateway?.productionAnalysis && (
          <div className="sub-navigation-analysis">
            <span>
              <Link className="button button-secondary button-small" to={`/dashboard/${_id}/`}>
                {i18n.t('dashbo')}
              </Link>
            </span>
            <span>
              <Link className="button button-secondary button-small" to={`/production-analysis/overview/${_id}`}>
                <i className="la la-bar-chart" />
                {i18n.t('analysis')}
              </Link>
            </span>
          </div>
          )}
        </Box>
        {gateway && !gateway.productionAnalysis && (
        <Link to={`/dashboard/${_id}/`} className="dashboard-link">
          {i18n.t('dashbo')}
        </Link>
        )}
      </div>
    </div>
  );
};

UserInfoSub.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default UserInfoSub;
