import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';

import { additionalFields, devicesInitialValues } from '../utils';
import displayField from '../utils/displayField';
import { IPField } from '../../../components/ReduxFormFields';
import DeviceIdRTU from './DeviceIdRTU';

import NoteField from './NoteField';
import { getUpdatedFieldList } from '../utils/getUpdatedFieldList';

import '../index.scss';

/**
 * Renders additional fields for some device
 * @function RenderFieldsByDevName
 * @memberof module:UserDevices
 * @param {Object} props
 * @param {string} props.deviceName Name of devices (which fields to render).
 */
const RenderFieldsByDevName = (props) => {
  const { deviceName, data = {}, myRoleType, modal = {}, isShowDeviceActivity, newDeviceType = '', type = '', mode, isCarExists, license, userApp, devicesList, isBatteryModeFeatureEnabled, tariffType } = props;

  const fields = useMemo(() => getUpdatedFieldList(deviceName, data, newDeviceType),
    [data.heatPumpPVReady, data.warmWaterDevices, deviceName, data.warmWaterDevices2Step, data.warmWaterDevices3Step, data.heatPumpSGready, data.chargingMode, newDeviceType] //eslint-disable-line
  );

  return (
    <>
      {fields.map((fieldID) => {
        if (!displayField(deviceName, fieldID, myRoleType)) {
          return null;
        }

        if (['ctaTypeRTU', 'ctaType', 'ctaTypeCarel'].includes(fieldID) && mode === 'edit' && !['root', 'solar_admin'].includes(myRoleType)) {
          return null;
        }

        const { enabledIf, validateIf, disabledFor, validate, isFieldVisible, ...fieldProps } = additionalFields[fieldID];

        if (enabledIf) {
          const showField = enabledIf.every((condition) => {
            if (condition?.ignore?.includes(deviceName)) {
              return true;
            }
            const expectedValues = condition.values;
            const actualValue = data[condition.fieldID];
            return expectedValues.indexOf(actualValue) !== -1;
          });
          if (!showField) {
            return null;
          }
        }

        if (type === 'Battery') {
          if (isBatteryModeFeatureEnabled || Object.hasOwn(data, 'batteryMode')) {
            const { allowedModes } = devicesList?.find((device) => device.type === 'Battery' && device.name === deviceName) || {};

            if (['batteryChargingMode', 'socUpperLimit', 'socLowerLimit', 'socLimit', 'batteryPower'].includes(fieldID)) {
              return null;
            }

            if (fieldID === 'switchingTimes') {
              fieldProps.allowedBatteryModeOptions = allowedModes;
              fieldProps.tariffType = tariffType;
            }

            if (fieldID === 'batteryMode' && fieldProps) {
              fieldProps.allowedBatteryModeOptions = allowedModes;
              fieldProps.mode = mode;
              fieldProps.license = license;
              fieldProps.tariffType = tariffType;
            }
          } else if (fieldID === 'batteryMode') {
            return null;
          }

          if (isFieldVisible === false) {
            return null;
          }
        }

        if (fieldID === 'Device_ID_RTU') {
          return (
            <Fragment key={`${fieldID}`}>
              <DeviceIdRTU
                sensorId={modal?.data?.id || modal?.data?._id}
                value={data?.Device_ID_RTU}
              />
            </Fragment>
          );
        }

        const containerStyles = `form-group m-form__group input-field ${fieldProps.type === 'checkbox' ? ' devices-checkbox-parent' : ''}`;

        const validators = validateIf?.deviceName.includes(deviceName) && validateIf?.fieldName === fieldID
          ? validate.concat(validateIf?.validate)
          : validate;

        const disabledField = disabledFor?.deviceName.includes(deviceName) ? disabledFor?.roles : [];

        const isDisabledAutoComplete = fields.includes('Password')
          && !fields.includes('User');

        if (fieldID === 'deviceIdentification' && myRoleType === 'end_user') {
          return null;
        }

        if (fieldID === 'ip') {
          return (
            <Fragment key={`${fieldID}`}>
              <IPField
                device_group={props.deviceName}
                type={props.type}
                formID={props.formID}
                isShow
              />
            </Fragment>
          );
        }

        if (fieldID === 'deviceActivity') {
          return isShowDeviceActivity ? (
            <div className="mb-4" key={fieldID}>
              <Field
                name="deviceActivity"
                {...additionalFields.deviceActivity}
              />
            </div>
          ) : null;
        }

        if (fieldID === 'notes') {
          return (
            <Fragment key={fieldID}>
              <NoteField />
            </Fragment>
          );
        }

        if (fieldID === 'support_url') {
          return null;
        }

        if (fieldID === 'waterHeaterMode' && fieldProps) {
          fieldProps.waterHeaterType = data?.waterHeaterType || devicesInitialValues[deviceName]?.data?.waterHeaterType;
        }

        if (['switchingTimes', 'chargingMode', 'heatPumpChargingMode', 'homeApplianceChargingMode', 'smartPlugChargingType', 'waterHeaterMode'].includes(fieldID) && fieldProps) {
          fieldProps.license = license;
          fieldProps.userApp = userApp;
        }

        const Wrapper = fieldID === 'switchingTimes' ? FieldArray : Field;

        return (
          <div key={`${fieldID}`} className={`${containerStyles} ${(fieldID === 'switchingTimes' || fieldID === 'OcppUrl') && 'switchingTimes-field'}`}>
            {isDisabledAutoComplete && fieldID === 'Password' && (
              <Field name="data.fakeField" component="input" type="email" className="fakeField" />
            )}
            <Wrapper
              name={`data.${fieldID}`}
              {...fieldProps}
              mode={mode}
              deviceType={type}
              validate={validators}
              deviceName={deviceName}
              disabled={disabledField.includes(myRoleType)}
              isCarExists={isCarExists}
            />
          </div>
        );
      })}
    </>
  );
};

RenderFieldsByDevName.propTypes = {
  data: PropTypes.instanceOf(Object),
  error: PropTypes.instanceOf(Object),
  modal: PropTypes.instanceOf(Object),
  myRoleType: PropTypes.string.isRequired,
  deviceName: PropTypes.string,
  notes: PropTypes.string,
  formID: PropTypes.string,
  type: PropTypes.string,
  newDeviceType: PropTypes.string,
  isShowDeviceActivity: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  userApp: PropTypes.string.isRequired,
  isCarExists: PropTypes.bool,
  license: PropTypes.instanceOf(Object).isRequired,
  devicesList: PropTypes.instanceOf(Object).isRequired,
  isBatteryModeFeatureEnabled: PropTypes.bool,
  tariffType: PropTypes.string
};

export default RenderFieldsByDevName;
