import React from 'react';
import PropTypes from 'prop-types';
import { Box, Slider } from '@mui/material';
import { Field } from 'redux-form';
import { greaterThan, lessThan } from '../../../../validation';
import additionalFields from '../../../utils/additionalFields';
import TextField from './TextField';

const StandardMode = (props) => {
  const { formData, change } = props;
  const { standardLowerSocLimit, standardUpperSocLimit } = formData || {};

  const onChangeSlider = (event, values) => {
    if (values[0] !== standardLowerSocLimit) {
      change('standardLowerSocLimit', values[0]);
    }
    if (values[1] !== standardUpperSocLimit) {
      change('standardUpperSocLimit', values[1]);
    }
  };

  const lessThanStandardUpperSocLimit = React.useMemo(
    () => lessThan(standardUpperSocLimit, 'standardUpperSocLimit.label'),
    [standardUpperSocLimit]
  );
  const greaterThanStandardLowerSocLimit = React.useMemo(
    () => greaterThan(standardLowerSocLimit, 'standardLowerSocLimit.label'),
    [standardLowerSocLimit]
  );

  return (
    <>
      <Box className="input-field devices-checkbox-parent">
        <Field {...additionalFields.standardStandaloneAllowed} name="standardStandaloneAllowed" />
      </Box>
      <Slider
        sx={{
          margin: '36px 0',
          '& .MuiSlider-thumb': {
            color: '#fff'
          },
          '& .MuiSlider-rail': {
            background: `linear-gradient(to right, #F2D649, #F2D649 ${standardLowerSocLimit}%, #C14F1A ${standardLowerSocLimit}%, #C14F1A ${standardUpperSocLimit}%, #0000001A ${standardUpperSocLimit}%, #0000001A 100%)`,
            opacity: 1
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: 'unset',
            top: 0,
            color: 'text.primary',
            opacity: 0.5
          }
        }}
        track={false}
        aria-labelledby="track-false-range-slider"
        valueLabelDisplay="on"
        valueLabelFormat={(value) => `${value}%`}
        value={[standardLowerSocLimit, standardUpperSocLimit]}
        min={0}
        max={100}
        disableSwap
        onChange={onChangeSlider}
      />
      <Box className="field-wrapper">
        <TextField
          {...additionalFields.standardLowerSocLimit}
          name="standardLowerSocLimit"
          validate={[
            ...additionalFields.standardLowerSocLimit.validate,
            lessThanStandardUpperSocLimit
          ]}
        />
        <TextField
          {...additionalFields.standardUpperSocLimit}
          name="standardUpperSocLimit"
          validate={[
            ...additionalFields.standardUpperSocLimit.validate,
            greaterThanStandardLowerSocLimit
          ]}
        />
      </Box>
    </>
  );
};

StandardMode.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired
};

export default StandardMode;
