import React from 'react';
import PropTypes from 'prop-types';
import { Box, Slider } from '@mui/material';
import TextField from './TextField';
import { greaterThan, lessThan } from '../../../../validation';
import additionalFields from '../../../utils/additionalFields';

const PeakShavingMode = (props) => {
  const { formData, change } = props;
  const { peakShavingSocDischargeLimit, peakShavingSocMaxLimit } = formData || {};

  const onChangeSlider = (event, values) => {
    if (values[0] !== peakShavingSocDischargeLimit) {
      change('peakShavingSocDischargeLimit', values[0]);
    }
    if (values[1] !== peakShavingSocMaxLimit) {
      change('peakShavingSocMaxLimit', values[1]);
    }
  };

  const lessThanPeakShavingSocMax = React.useMemo(
    () => lessThan(peakShavingSocMaxLimit, 'peakShavingSocMaxLimit.label'),
    [peakShavingSocMaxLimit]
  );
  const greaterThanPeakShavingSocDischarge = React.useMemo(
    () => greaterThan(peakShavingSocDischargeLimit, 'peakShavingSocDischargeLimit.label'),
    [peakShavingSocDischargeLimit]
  );

  return (
    <>
      <Slider
        sx={{
          marginBottom: '36px',
          '& .MuiSlider-thumb': {
            color: '#fff'
          },
          '& .MuiSlider-rail': {
            background: `linear-gradient(to right, #D6D8D5, #D6D8D5 ${peakShavingSocDischargeLimit}%, #5BC35F ${peakShavingSocDischargeLimit}%, #5BC35F ${peakShavingSocMaxLimit}%, #0000001A ${peakShavingSocMaxLimit}%, #0000001A 100%)`,
            opacity: 1
          },
          '& .MuiSlider-valueLabel': {
            backgroundColor: 'unset',
            top: 0,
            color: 'text.primary',
            opacity: 0.5
          },
          '& .MuiSlider-markLabel': {
            color: 'text.primary',
            opacity: 0.5
          },
          '& .MuiSlider-mark': {
            backgroundColor: 'unset'
          }
        }}
        track={false}
        aria-labelledby="track-false-range-slider"
        valueLabelDisplay="on"
        valueLabelFormat={(value) => `${value}%`}
        value={[peakShavingSocDischargeLimit, peakShavingSocMaxLimit]}
        min={0}
        max={100}
        disableSwap
        onChange={onChangeSlider}
        marks={[0, 20, 40, 60, 80, 100].map((value) => ({
          value,
          label: `${value}%`
        }))}
      />
      <Box className="field-wrapper" sx={{ marginBottom: '36px' }}>
        <TextField
          {...additionalFields.peakShavingSocDischargeLimit}
          name="peakShavingSocDischargeLimit"
          validate={[
            ...additionalFields.peakShavingSocDischargeLimit.validate,
            lessThanPeakShavingSocMax
          ]}
        />
        <TextField
          {...additionalFields.peakShavingSocMaxLimit}
          name="peakShavingSocMaxLimit"
          validate={[
            ...additionalFields.peakShavingSocMaxLimit.validate,
            greaterThanPeakShavingSocDischarge
          ]}
          labelColor="#5BC35F"
        />
      </Box>
      <Box className="field-wrapper">
        <TextField {...additionalFields.peakShavingMaxGridPower} name="peakShavingMaxGridPower" />
        <TextField {...additionalFields.peakShavingRechargePower} name="peakShavingRechargePower" />
      </Box>
    </>
  );
};

PeakShavingMode.propTypes = {
  formData: PropTypes.instanceOf(Object),
  change: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired
};

export default PeakShavingMode;
