import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../api_helper';

export const FeatureFlagsApi = createApi({
  reducerPath: 'FeatureFlagsApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getFeatureFlags: builder.query({
      query: () => ({ url: '/feature-flags', method: 'get' })
    }),
    getIsFeatureEnabled: builder.query({
      query: ({ userId, featureFlagName }) => ({
        url: `/feature-flags/user/${userId}?featureFlag=${featureFlagName}`,
        method: 'get'
      })
    })
  })
});

export const {
  useGetFeatureFlagsQuery,
  useGetIsFeatureEnabledQuery
} = FeatureFlagsApi;
